import React, { useEffect, useState } from 'react';
import { Card, Row, Col } from 'antd';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid } from 'recharts';
import { fetchShiftsReport } from '../services/apiService';
import { getCurrentWeekDates } from '../utils/dateUtils';

function Dashboard({ bakeries }) {
  const [shiftData, setShiftData] = useState([]);

  useEffect(() => {
    // Fetch the data when the component mounts
    const fetchData = async () => {
      const { startDate, endDate } = getCurrentWeekDates(); // Get the start and end dates of the current week
      try {
        const data = await fetchShiftsReport(startDate, endDate, 1); // Use the dates to fetch the data
        setShiftData(data);
      } catch (error) {
        console.error("Error fetching shift report:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="dashboard">
      <Row >
        
        <Col >
          <Card title="Shift Report" bordered={false}>
            <BarChart width={1000} height={300} data={shiftData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="total_batches" fill="#8884d8" name="Total Batches" />
              <Bar dataKey="worker_count" fill="#82ca9d" name="Worker Count" />
            </BarChart>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Dashboard;
