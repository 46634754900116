import React, { useState, useEffect, useContext } from 'react';
import { Table, Button, Modal, DatePicker, TimePicker, Form, Select, message } from 'antd';
import moment from 'moment';
import { GlobalContext } from '../GlobalContext';
import { fetchShiftSchedulesForBakery, createShiftSchedule, updateShiftSchedule } from '../services/apiService';

const { RangePicker } = DatePicker;
const { Option } = Select;

const ShiftSchedulePage = () => {
  const { selectedBakery, token } = useContext(GlobalContext);
  const [shifts, setShifts] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    const loadShifts = async () => {
      if (selectedBakery) {
        const data = await fetchShiftSchedulesForBakery(selectedBakery.id, token);
        setShifts(data);
      }
    };
    loadShifts();
  }, [selectedBakery, token]);

  const addShift = async () => {
    const { startDate, endDate, startTime, endTime, daysOfWeek } = formData;

    if (!startDate || !endDate || !startTime || !endTime || !daysOfWeek) {
      message.error('All fields are required.');
      return;
    }

    const newShift = {
      bakery_id: selectedBakery.id,
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD'),
      start_time: startTime.format('HH:mm'),
      end_time: endTime.format('HH:mm'),
      days_of_week: daysOfWeek,
    };

    if (isShiftOverlapping(newShift, shifts)) {
      message.error('Shift is overlapping with an existing shift.');
      return;
    }

    await createShiftSchedule(newShift, token);
    setIsModalVisible(false);
    const updatedShifts = await fetchShiftSchedulesForBakery(selectedBakery.id, token);
    setShifts(updatedShifts);
  };
  
  const isShiftOverlapping = (newShift, existingShifts) => {
    const newStart = moment(newShift.start_date);
    const newEnd = moment(newShift.end_date);
    const newStartTime = moment(newShift.start_time, 'HH:mm');
    const newEndTime = moment(newShift.end_time, 'HH:mm');
  
    return existingShifts.some((shift) => {
      const existingStart = moment(shift.start_date);
      const existingEnd = moment(shift.end_date);
      const existingStartTime = moment(shift.start_time, 'HH:mm');
      const existingEndTime = moment(shift.end_time, 'HH:mm');
  
      const isDateOverlapping = newStart.isBefore(existingEnd) && newEnd.isAfter(existingStart);
      const isTimeOverlapping = newStartTime.isBefore(existingEndTime) && newEndTime.isAfter(existingStartTime);
      const isDayOverlapping = newShift.days_of_week.some((day) => shift.days_of_week.includes(day));
  
      return isDateOverlapping && isTimeOverlapping && isDayOverlapping;
    });
  };
  
  

  const columns = [
    {
      title: 'Start Date',
      dataIndex: 'start_date',
      key: 'start_date',
    },
    {
      title: 'End Date',
      dataIndex: 'end_date',
      key: 'end_date',
    },
    {
      title: 'Start Time',
      dataIndex: 'start_time',
      key: 'start_time',
    },
    {
      title: 'End Time',
      dataIndex: 'end_time',
      key: 'end_time',
    },
    {
        title: 'Days of Week',
        dataIndex: 'days_of_week',
        key: 'days_of_week',
        render: (daysOfWeek) => Array.isArray(daysOfWeek) ? daysOfWeek.join(', ') : '',
      },
  ];

  return (
    <div>
      <Button type="primary" onClick={() => setIsModalVisible(true)}>
        Add Shift
      </Button>
      <Table dataSource={shifts} columns={columns} rowKey={(record, index) => index.toString()} />
      <Modal title="Add Shift" visible={isModalVisible} onOk={addShift} onCancel={() => setIsModalVisible(false)}>
        <Form layout="vertical">
        <Form.Item label="Start Date">
            <DatePicker
              onChange={(date) => setFormData({ ...formData, startDate: date })}
            />
          </Form.Item>
          <Form.Item label="End Date">
            <DatePicker
              onChange={(date) => setFormData({ ...formData, endDate: date })}
            />
          </Form.Item>
          <Form.Item label="Start Time">
            <TimePicker
              format="HH:mm"
              onChange={(time) => setFormData({ ...formData, startTime: time })}
            />
          </Form.Item>
          <Form.Item label="End Time">
            <TimePicker
              format="HH:mm"
              onChange={(time) => setFormData({ ...formData, endTime: time })}
            />
          </Form.Item>
          <Form.Item label="Days of Week">
            <Select
              mode="multiple"
              placeholder="Select days"
              onChange={(days) => setFormData({ ...formData, daysOfWeek: days })}
            >
              <Option value="Monday">Monday</Option>
              <Option value="Tuesday">Tuesday</Option>
              <Option value="Wednesday">Wednesday</Option>
              <Option value="Thursday">Thursday</Option>
              <Option value="Friday">Friday</Option>
              <Option value="Saturday">Saturday</Option>
              <Option value="Sunday">Sunday</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ShiftSchedulePage;
