import React, { useState, useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import { GlobalContext } from "../GlobalContext";
import AppointmentForm from "../components/AppointmentForm";
import { useBakeryData } from "../utils/useBakeryData";
import translations from "../translations";
import { getHebrewWeekName } from "../utils/dateUtils";
import {
  createCustomer,
  createAppointment,
  updateAppointment,
  deleteAppointment,
  fetchShiftSchedulesForBakery,
} from "../services/apiService";
function AppointmentsPage() {
  const { appointmentId } = useParams();
  const navigate = useNavigate();

  const { selectedBakery, token, language } = useContext(GlobalContext);
  const {
    appointments,
    setAppointments,
    customers,
    setCustomers,
    availableBakingMethods,
    availableFlourTypes,
  } = useBakeryData(selectedBakery, token);

  const [panelType, setPanelType] = useState(null); // 'create' or 'details'
  const [selectedDate, setSelectedDate] = useState(null);
  const [appointmentData, setAppointmentData] = useState({});
  const [selectedDuration, setSelectedDuration] = useState(0);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [bakingMethods, setBakingMethods] = useState([]);
  const [flourTypes, setFlourTypes] = useState([]);
  const [newCustomer, setNewCustomer] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
  });
  const DEFAULT_COLOR = "#3788d8";

  const resetPanelState = () => {
    setSelectedSlots([]);
    setSelectedDuration(0);
  };
  

  useEffect(() => {
    if (appointmentId && appointments.length > 0) {
      const detailedAppointment = appointments.find(
        (appt) => appt.id === parseInt(appointmentId)
      );
      if (detailedAppointment) {
        setSelectedAppointment(detailedAppointment);
        setAppointmentData({
          id: detailedAppointment.id,
          customer_id: detailedAppointment.customer.id,
          pounds: detailedAppointment.pounds,
          comments: detailedAppointment.comments,
        });
        setBakingMethods(detailedAppointment.baking_methods); // Corrected
        setFlourTypes(detailedAppointment.flour_types); // Corrected
        setSelectedDate(detailedAppointment.appointment_date_time);
        setPanelType("edit");
      }
    }
  }, [appointmentId, appointments]);

  //show shifts in the calendar
  const [backgroundEvents, setBackgroundEvents] = useState([]);

  useEffect(() => {
    const fetchShifts = async () => {
      const fetchedShifts = await fetchShiftSchedulesForBakery(
        selectedBakery.id,
        token
      );
      const dailyShifts = [];

      fetchedShifts.forEach((shift) => {
        const startDate = moment(shift.start_date);
        const endDate = moment(shift.end_date);

        for (
          let m = moment(startDate);
          m.isBefore(endDate) || m.isSame(endDate);
          m.add(1, "days")
        ) {
          if (shift.days_of_week.includes(m.format("dddd"))) {
            dailyShifts.push({
              start: `${m.format("YYYY-MM-DD")}T${shift.start_time}`,
              end: `${m.format("YYYY-MM-DD")}T${shift.end_time}`,
              rendering: "background",
              backgroundColor: "#ff9f89",
              classNames: ["narrow-bg-event"],
            });
          }
        }
      });

      setBackgroundEvents(dailyShifts);
    };

    fetchShifts();
  }, [selectedBakery, token]);

  const [hebrewWeek, setHebrewWeek] = useState("");

  const handleDateSelect = (selectInfo) => {
        // Convert start and end times to local time considering timezone offset
        const offset = new Date().getTimezoneOffset();

        const localStartDate = new Date(selectInfo.startStr);
        localStartDate.setMinutes(localStartDate.getMinutes() - offset);
    
        const localEndDate = new Date(selectInfo.endStr);
        localEndDate.setMinutes(localEndDate.getMinutes() - offset);
    
        // Calculate duration in minutes
        const duration = (localEndDate - localStartDate) / (1000 * 60); // Duration in minutes
        console.log("Selected duration:", duration);
        setSelectedDuration(duration);
    
        // Set the selected date as the start of the selection
        setSelectedDate(localStartDate.toISOString().slice(0, 19).replace("T", " "));
        console.log("Selected date:", localStartDate);
    
        // Calculate and set the selected slots
        const slots = [];
        for (let time = new Date(localStartDate); time < localEndDate; time.setMinutes(time.getMinutes() + 15)) {
            slots.push(new Date(time));
        }
        setSelectedSlots(slots);
        console.log("Selected slots:", slots);

    if (panelType !== "create") {
      resetFormState(); // Reset the form state
    }

    setPanelType("create");
  };

  const updateHebrewWeekForCalendar = (dateInfo) => {
    const parshaInfoStart = getHebrewWeekName(
      dateInfo.startStr,
      "he-x-NoNikud"
    );
    setHebrewWeek(parshaInfoStart);
  };

  const handleEventClick = (clickInfo) => {
    if (clickInfo.event.classNames.includes("narrow-bg-event")) {
      return;
    }

    setSelectedAppointment(clickInfo.event.extendedProps);
    setAppointmentData({
      id: clickInfo.event.extendedProps.id,
      customer_id: clickInfo.event.extendedProps.customer.id,
      pounds: clickInfo.event.extendedProps.pounds,
      comments: clickInfo.event.extendedProps.comments,
    });
    setBakingMethods(clickInfo.event.extendedProps.bakingMethods);
    setFlourTypes(clickInfo.event.extendedProps.flourTypes);
    setSelectedDate(clickInfo.event.extendedProps.appointment_date_time);

    setPanelType("edit");
    resetPanelState();
    navigate(`/appointments/${clickInfo.event.extendedProps.id}`);
  };

  const saveAppointment = async () => {
    let customerId = appointmentData.customer_id;
    let newCustomerCreated = null;
    if (!customerId) {
      if (
        !newCustomer.first_name ||
        !newCustomer.last_name ||
        !newCustomer.phone_number
      ) {
        alert("Please fill in all fields for the new customer.");
        return;
      }
    }
    // If there's no customer ID and the new customer details are provided, create a new customer
    if (
      (!customerId && newCustomer.first_name) ||
      newCustomer.last_name ||
      newCustomer.phone_number
    ) {
      console.log("Creating new customer:", newCustomer);
      try {
        const createdCustomer = await createCustomer(
          newCustomer.first_name,
          newCustomer.last_name,
          newCustomer.phone_number,
          selectedBakery.id,
          token
        );
        customerId = createdCustomer.id;
        newCustomerCreated = createdCustomer;
        setCustomers((prevCustomers) => [...prevCustomers, createdCustomer]);
      } catch (error) {
        console.error("Error creating customer:", error);
        return;
      }
    }

    if (panelType === "create") {
      const appointmentPromises = selectedSlots.map(async (slot) => {
        const appointmentTime = slot.toISOString().slice(0, 19).replace("T", " ");
        try {
          const newAppointment = await createAppointment(
            customerId,
            appointmentTime,
            appointmentData.pounds,
            appointmentData.comments,
            bakingMethods,
            flourTypes,
            token
          );
          newAppointment.customer = newCustomerCreated || customers.find((c) => c.id === customerId);
          return newAppointment;
        } catch (error) {
          console.error("Error creating appointment:", error);
        }
      });
  
      try {
        const newAppointments = await Promise.all(appointmentPromises);
        setAppointments((prevAppointments) => [...prevAppointments, ...newAppointments.filter(appt => appt)]);
      } catch (error) {
        console.error("Error in batch creating appointments:", error);
      }
    } else if (panelType === "edit" && selectedAppointment) {
      let updatedAppointment;
      try {
        updatedAppointment = await updateAppointment(
          selectedAppointment.id,
          customerId,
          selectedDate,
          appointmentData.pounds,
          appointmentData.comments,
          bakingMethods,
          flourTypes,
          token
        );

        setAppointments((prevAppointments) =>
          prevAppointments.map((appt) =>
            appt.id === selectedAppointment.id ? updatedAppointment : appt
          )
        );
      } catch (error) {
        console.error("Error updating appointment:", error);
        return;
      }
    }

    // Reset the form fields
    resetFormState();
    setPanelType(null);
    resetPanelState();
    navigate("/appointments");
  };

  const cancelAppointment = async (appointmentId) => {
    console.log("Cancel appointment:", appointmentId);
    try {
      await deleteAppointment(appointmentId, token);
      setAppointments((prevAppointments) =>
        prevAppointments.filter((appt) => appt.id !== appointmentId)
      );
      setPanelType(null); // Hide the form panel
      navigate("/appointments");
    } catch (error) {
      console.error("Error deleting appointment:", error);
    }
  };

  const events = appointments.map((appointment) => {
    // Determine the color for the appointment
    let backgroundColor = DEFAULT_COLOR;
    if (
      appointment.baking_methods_details &&
      appointment.baking_methods_details.length > 0 &&
      appointment.baking_methods_details[0].color
    ) {
      backgroundColor = appointment.baking_methods_details[0].color;
    }

    return {
      title: `${appointment.customer.first_name} ${appointment.customer.last_name}`,
      classNames: ["regular-event"],
      start: appointment.appointment_date_time,
      end: new Date(
        new Date(appointment.appointment_date_time).getTime() + 15 * 60000
      ).toISOString(),
      backgroundColor: backgroundColor, // Set the determined color
      extendedProps: {
        id: appointment.id,
        customer_id: appointment.customer_id,
        appointment_date_time: appointment.appointment_date_time,
        pounds: appointment.pounds,
        comments: appointment.comments,
        created_at: appointment.created_at,
        updated_at: appointment.updated_at,
        customer: appointment.customer,
        bakingMethods: appointment.baking_methods,
        flourTypes: appointment.flour_types,
      },
    };
  });

  console.log("Mapped events:", events);
  const resetFormState = () => {
    setAppointmentData({});
    setBakingMethods([]);
    setFlourTypes([]);
    setNewCustomer({
      first_name: "",
      last_name: "",
      phone_number: "",
    });
  };

  const handleEventDidMount = (info) => {
    if (info.event.classNames.includes("narrow-bg-event")) {
      info.el.style.width = "3%";
      info.el.style.backgroundColor = "#ffaaaa";
      info.el.style.border = "none";
      info.el.style.marginLeft = "0";
    }
    if (info.event.classNames.includes("regular-event")) {
      const parentDiv = info.el.parentElement;
      if (parentDiv) {
        parentDiv.style.left = "3%"; // Add your CSS styles here
      }
    }
  };

  return (
    <div style={{ display: "flex", height: "100vh", overflow: "hidden" }}>
      <div style={{ flex: 1, overflow: "hidden" }}>
        <h2>Appointments {` ${hebrewWeek}`}</h2>
        <div style={{ height: "calc(100vh - 80px)", overflow: "hidden" }}>
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView="timeGridWeek"
            slotDuration="00:15:00"
            selectable={true}
            select={handleDateSelect}
            eventClick={handleEventClick}
            height="100%"
            contentHeight="calc(100vh - 60px)"
            events={[...events, ...backgroundEvents]}
            displayEventTime={false}
            hiddenDays={[6]}
            scrollTimeReset={false}
            scrollTime={"09:00:00"}
            datesSet={updateHebrewWeekForCalendar}
            eventDidMount={handleEventDidMount}
          />
        </div>
      </div>
      {panelType && (
        <div
          style={{
            width: "300px",
            backgroundColor: "#f7f7f7",
            padding: "20px",
            borderLeft: "1px solid #ccc",
          }}
        >
          <h2>
            {panelType === "create"
              ? translations[language].addAppointment
              : translations[language].editAppointment}
          </h2>
          <AppointmentForm
            panelType={panelType}
            selectedDate={selectedDate}
            appointmentData={appointmentData}
            setAppointmentData={setAppointmentData}
            selectedDuration={selectedDuration}
            customers={customers}
            bakingMethods={bakingMethods}
            setBakingMethods={setBakingMethods}
            availableBakingMethods={availableBakingMethods}
            flourTypes={flourTypes}
            setFlourTypes={setFlourTypes}
            availableFlourTypes={availableFlourTypes}
            newCustomer={newCustomer}
            setNewCustomer={setNewCustomer}
            saveAppointment={saveAppointment}
            cancelAppointment={cancelAppointment}
            setPanelType={setPanelType}
            resetPanelState={resetPanelState}
            navigate={navigate}
          />
        </div>
      )}
    </div>
  );
}

export default AppointmentsPage;
