import React from 'react';
import { Modal, Form, Input } from 'antd';

function LapDetailsModal({ isVisible, lap, onOk, onCancel, onChange }) {
  return (
    <Modal 
      title={`Lap ${lap?.id} Details`} 
      visible={isVisible} 
      onOk={onOk} 
      onCancel={onCancel}
    >
      <Form>
        <Form.Item label="Time Started">
          <Input 
            value={lap?.time_started} 
            onChange={(e) => onChange('time_started', e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Batches">
          <Input 
            value={lap?.batches} 
            onChange={(e) => onChange('batches', e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Dough at table">
          <Input 
            value={lap?.dough_at_table} 
            onChange={(e) => onChange('dough_at_table', e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Dough finished">
          <Input 
            value={lap?.dough_finished} 
            onChange={(e) => onChange('dough_finished', e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Comments">
          <Input 
            value={lap?.comments} 
            onChange={(e) => onChange('comments', e.target.value)}
          />
        </Form.Item>
        {/* Add other lap details as needed */}
      </Form>
    </Modal>
  );
}

export default LapDetailsModal;
