import React from 'react';
import { CellTemplate, Cell, Compatible, Uncertain } from '@silevis/reactgrid';

export interface ClickableHeaderCell extends Cell {
  type: 'clickableHeader';
  text: string;
  lap: any;  // Adjusted here to carry the entire lap object
}

export class ClickableHeaderCellTemplate implements CellTemplate<ClickableHeaderCell> {
  constructor(private handleLapClick: (lap: any) => void) {}

  getCompatibleCell(uncertainCell: Uncertain<ClickableHeaderCell>): Compatible<ClickableHeaderCell> {
    const text = uncertainCell.text || '';
    const lap = uncertainCell.lap || {};  // Adjusted here to carry the entire lap object
    const value = 0;  // Add this line to satisfy the value property requirement
    return { ...uncertainCell, text, lap, value };  // Updated here to include lap
  }

  update(
    cell: Compatible<ClickableHeaderCell>,
    cellToMerge: Uncertain<ClickableHeaderCell>
  ): Compatible<ClickableHeaderCell> {
    return this.getCompatibleCell({ ...cell, text: cellToMerge.text, lap: cellToMerge.lap });  // Updated here to include lap
  }

  isFocusable() {
    return false;
  }

  render(
    cell: Compatible<ClickableHeaderCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<ClickableHeaderCell>, commit: boolean) => void
  ) {
    return (
      <div
        onClick={() => this.handleLapClick(cell.lap)}  // Updated here to pass the entire lap object
        style={{ cursor: 'pointer' }}
      >
        {cell.text}
      </div>
    );
  }


}
