import { HDate, Sedra } from '@hebcal/core';

export const getCurrentWeekDates = () => {
    const currentDate = new Date();
    const currentDay = currentDate.getDay();
    const daysToSubtract = currentDay === 0 ? 6 : currentDay - 1; // If today is Sunday, subtract 6 days, else subtract (currentDay - 1) days
  
    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(currentDate.getDate() - daysToSubtract);
  
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
  
    const formatDate = (date) => {
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    };
  
    return {
      startDate: formatDate(startOfWeek),
      endDate: formatDate(endOfWeek),
    };
  };

  export const getHebrewWeekName = (date, locale = "he-x-NoNikud") => {
    const hDate = new HDate(new Date(date));
    const sedra = new Sedra(hDate.getFullYear(), false);
    const fullString = sedra.getString(hDate, locale);
    return fullString.replace(/^פרשת\s+/, '');
  };

  export const getHebrewDayLetter = (dayIndex) => {
    const hebrewDays = ["א'", "ב'", "ג'", "ד'", "ה'", "ו'", "ש'"];
    return hebrewDays[dayIndex];
  };

  export const getHebrewDate = (date) => {
    const hDate = new HDate(date);
    const renderedDate = hDate.renderGematriya(true).split(' ');
    const day = renderedDate[0];
    const month = renderedDate[1];
    return `${day} ${month}`;
  };
  