import React, { useState } from 'react';
import { Table, Input, InputNumber, Select } from 'antd';

const { Option } = Select;

const LapDetailsPage = () => {
  const [shiftData, setShiftData] = useState({
    Morning: [
      {
        key: '1',
        timeStarted: '10:00',
        batches: 5,
        bakingMethod: 'Oven',
        flourType: 'All-purpose',
        doughAtTable: '10:05',
        doughFinished: '10:10',
        comments: 'Good',
      },
      // Add more dummy data for Morning shift
    ],
    Evening: [
      {
        key: '2',
        timeStarted: '18:00',
        batches: 4,
        bakingMethod: 'Microwave',
        flourType: 'Whole Wheat',
        doughAtTable: '18:05',
        doughFinished: '18:08',
        comments: 'Average',
      },
      // Add more dummy data for Evening shift
    ],
    // Add more shifts if needed
  });

  const columns = [
    {
      title: 'Time Started (m:s)',
      dataIndex: 'timeStarted',
      key: 'timeStarted',
      render: (text) => <Input defaultValue={text} />,
    },
    {
      title: 'Batches',
      dataIndex: 'batches',
      key: 'batches',
      render: (text) => <InputNumber defaultValue={text} />,
    },
    {
      title: 'Baking Method',
      dataIndex: 'bakingMethod',
      key: 'bakingMethod',
      render: (text) => (
        <Select defaultValue={text} style={{ width: 120 }}>
          <Option value="Oven">Oven</Option>
          <Option value="Microwave">Microwave</Option>
        </Select>
      ),
    },
    {
      title: 'Flour Type',
      dataIndex: 'flourType',
      key: 'flourType',
      render: (text) => (
        <Select defaultValue={text} style={{ width: 120 }}>
          <Option value="All-purpose">All-purpose</Option>
          <Option value="Whole Wheat">Whole Wheat</Option>
        </Select>
      ),
    },
    {
      title: 'Dough at Table (m:s)',
      dataIndex: 'doughAtTable',
      key: 'doughAtTable',
      render: (text) => <Input defaultValue={text} />,
    },
    {
      title: 'Dough Finished (m:s)',
      dataIndex: 'doughFinished',
      key: 'doughFinished',
      render: (text) => <Input defaultValue={text} />,
    },
    {
      title: 'Comments',
      dataIndex: 'comments',
      key: 'comments',
      render: (text) => <Input defaultValue={text} />,
    },
  ];

  return (
    <div>
      <h1>Lap Details</h1>
      {Object.keys(shiftData).map((shift) => (
        <div key={shift} style={{ marginBottom: '20px' }}>
          <h2>{shift} Shift</h2>
          <Table
            rowKey="key"
            dataSource={shiftData[shift]}
            columns={columns}
            pagination={false}
            bordered
          />
        </div>
      ))}
    </div>
  );
};

export default LapDetailsPage;
