const translations = {
    en: {
      dashboard: "Dashboard",
      workers: "Workers",
      addWorker: "Add Worker",
      firstName: "First Name",
      lastName: "Last Name",
      phoneNumber: "Phone Number",
      shifts: "Shifts",
      appointments: "Appointments",
      customers: "Customers",
      addCustomer: "Add Customer",
      customerDetails: "Customer Details",
      reports: "Reports",
      weeklyReports: "Weekly Reports",
      workerReports: "Worker Reports",
      logout: "Logout",
      selectBakery: "Select a Bakery",
      addAppointment: "Add Appointment",
      editAppointment: "Edit Appointment",
      bakingMethods: "Baking Methods",
      flourTypes: "Flour Types",
      pound: "Pound",
      update: "Update",
      cancel: "Cancel",
      add: "Add",
      close: "Close",
    },
    yi: {
      dashboard: "דאַשבאָרד",
      workers: "אַרבעטערס",
      addWorker: "לייג צו אן אַרבעטער",
      firstName: "ערשטע נאָמען",
      lastName: "לעצטע נאָמען",
      phoneNumber: "טעלעפאָן נומער",
      shifts: "שיכטן",
      appointments: "אַפּוינטמענטס",
      customers: "קאסטומערס",
      addCustomer: "לייג צו אַ קאסטומער",
      customerDetails: "קאסטומער דעטאלן",
      reports: "באַריכטן",
      weeklyReports: "וואכענטלעך באַריכטן",
      workerReports: "אַרבעטער באַריכטן",
      logout: "אויסלאגירן",
      selectBakery: "סעלעקטירן אַ בעקערי",
      addAppointment: "צולייגן אַפּוינטמענט",
      editAppointment: "טוישן אַפּוינטמענט",
      bakingMethods: "סדר האפי'",
      flourTypes: "קמח",
      pound: "פונט",
      update: "טויש",
      cancel: "צוריק",
      add: "לייג צו",
      close: "מאך צו",
    }
  };
  
  export default translations;
  