import React, { useState, useEffect, useContext } from 'react';
import { Table, DatePicker, Button } from 'antd';
import { GlobalContext } from '../GlobalContext';
import { useBakeryData } from '../utils/useBakeryData';
import { getHebrewDayLetter, getHebrewWeekName, getHebrewDate  } from '../utils/dateUtils';
import { CSVLink } from 'react-csv';

const SchedulePage = () => {
  const { selectedBakery, token } = useContext(GlobalContext);
  const { appointments } = useBakeryData(selectedBakery, token);
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [dateRange, setDateRange] = useState([]);

  useEffect(() => {
    if (dateRange && dateRange.length === 2) {
      const [startDate, endDate] = dateRange;
      const filtered = appointments.filter((appt) => {
        const apptDate = new Date(appt.appointment_date_time);
        return apptDate >= startDate && apptDate <= endDate;
      });
      setFilteredAppointments(filtered);
    } else {
      setFilteredAppointments(appointments);
    }
  }, [appointments, dateRange]);

  const transformForCSV = (appointments) => {
    return appointments.map((appt) => ({
      id: appt.id,
      date: new Date(appt.appointment_date_time).toLocaleDateString('en-US'),
      time: new Date(appt.appointment_date_time).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }),
      hebrewDay: getHebrewDayLetter(new Date(appt.appointment_date_time).getDay()),
      hebrewWeek: getHebrewWeekName(appt.appointment_date_time),
      firstName: appt.customer.first_name,
      lastName: appt.customer.last_name,
      phoneNumber: appt.customer.phone_number,
      flourTypes: appt.flour_types_details.map((flour) => flour.name).join(', '),
      pounds: appt.pounds,
      bakingMethods: appt.baking_methods_details.map((method) => method.name).join(', '),
      comments: appt.comments,
    }));
  };

  const csvHeaders = [
    { label: 'ID', key: 'id' },
    { label: 'Date', key: 'date' },
    { label: 'Time', key: 'time' },
    { label: 'Day', key: 'hebrewDay' },
    { label: 'Week', key: 'hebrewWeek' },
    { label: 'First Name', key: 'firstName' },
    { label: 'Last Name', key: 'lastName' },
    { label: 'Phone Number', key: 'phoneNumber' },
    { label: 'Flour Types', key: 'flourTypes' },
    { label: 'Pounds', key: 'pounds' },
    { label: 'Baking Methods', key: 'bakingMethods' },
    { label: 'Comments', key: 'comments' },
  ];
  
  const columns = [
    {
      title: 'Date',
      dataIndex: 'appointment_date_time',
      key: 'date',
      render: (text) => new Date(text).toLocaleDateString('en-US'),
    },
    {
        title: 'Hebrew Date',
        dataIndex: 'appointment_date_time',
        key: 'hebrewDate',
        render: (text) => getHebrewDate(new Date(text)),
      },
      
    {
      title: 'Time--------',
      dataIndex: 'appointment_date_time',
      key: 'time',
      render: (text) => new Date(text).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }),
    },
    {
      title: 'Day',
      dataIndex: 'appointment_date_time',
      key: 'hebrewDay',
      render: (text) => getHebrewDayLetter(new Date(text).getDay()),
    },
    {
      title: 'Week',
      dataIndex: 'appointment_date_time',
      key: 'hebrewWeek',
      render: (text) => getHebrewWeekName(text),
    },
    {
      title: 'First Name',
      dataIndex: ['customer', 'first_name'],
      key: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: ['customer', 'last_name'],
      key: 'lastName',
    },
    {
      title: 'Phone',
      dataIndex: ['customer', 'phone_number'],
      key: 'phoneNumber',
    },
    {
      title: 'Flour Types',
      dataIndex: 'flour_types_details',
      key: 'flourTypes',
      render: (flourTypes) => flourTypes.map((flour) => flour.name).join(', '),
    },
    {
      title: 'Pounds',
      dataIndex: 'pounds',
      key: 'pounds',
    },
    {
      title: 'Type',
      dataIndex: 'baking_methods_details',
      key: 'bakingMethods',
      render: (bakingMethods) => bakingMethods.map((method) => method.name).join(', '),
    },
    {
      title: 'Comments',
      dataIndex: 'comments',
      key: 'comments',
    },
  ];

  return (
    <div>
      <DatePicker.RangePicker onChange={(dates) => setDateRange(dates)} />
      <CSVLink data={transformForCSV(filteredAppointments)} headers={csvHeaders} filename={'schedule.csv'}>
        <Button type="primary">Export CSV</Button>
      </CSVLink>
      <Table dataSource={filteredAppointments} columns={columns} rowKey="id" />
    </div>
  );
};

export default SchedulePage;
