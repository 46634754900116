import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../GlobalContext";
import {
  fetchNotesForBakery,
  createNote,
  updateNote,
} from "../services/apiService";
import { useBakeryData } from "../utils/useBakeryData";
import {
  Table,
  Input,
  Button,
  Card,
  Form,
  DatePicker,
  Select,
  Typography,
  Space,
} from "antd";
import { useNavigate } from "react-router-dom";
import { FormOutlined } from "@ant-design/icons";
import moment from "moment";

const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;

function NotesPage() {
  const { selectedBakery, token } = useContext(GlobalContext);
  const { customers } = useBakeryData(selectedBakery, token);
  const [notes, setNotes] = useState([]);
  const [selectedNote, setSelectedNote] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);


  useEffect(() => {
    if (selectedBakery && token) {
      fetchNotesForBakery(selectedBakery.id, token).then(setNotes);
    }
  }, [selectedBakery, token]);

  const handleRowClick = (note) => {
    setSelectedNote(note);
    setIsCreating(false);
    setSelectedCustomerId(note.customer ? note.customer.id : null);
    form.setFieldsValue({
      ...note,
      due_date: note.due_date ? moment(note.due_date) : null,
      
    });
    navigate(`/notes/${note.id}`);
  };

  const handleCreateNote = () => {
    setIsCreating(true);
    setSelectedNote(null);
    form.resetFields();
    navigate("/notes/new");
  };

  const handleSubmit = async (values) => {
    const noteData = {
      ...values,
      due_date: values.due_date ? values.due_date.format('YYYY-MM-DD') : null,
      customer_id: selectedCustomerId,
      bakery_id: selectedBakery.id, 
    };

    // Check if no customer is selected and set customer_id to null
  if (selectedCustomerId === null || selectedCustomerId === undefined) {
    noteData.customer_id = null;
  }
  
    if (isCreating) {
      let newNote = await createNote(noteData, token);
  
      if (newNote && !newNote.customer && noteData.customer_id) {
        const customer = customers.find(c => c.id === noteData.customer_id);
        newNote.customer = customer; // Modify the object directly
      }
  
      setNotes(prevNotes => [newNote, ...prevNotes]);
    } else if (selectedNote) {
      let updatedNote = await updateNote(selectedNote.id, noteData, token);
  
      if (updatedNote && !updatedNote.customer && noteData.customer_id) {
        const customer = customers.find(c => c.id === noteData.customer_id);
        updatedNote.customer = customer; // Modify the object directly
      }
  
      setNotes(prevNotes => prevNotes.map(note => 
        note.id === selectedNote.id ? { ...note, ...updatedNote } : note
      ));
    }
  
    setIsCreating(false);
    setSelectedNote(null);
    setSelectedCustomerId(null);
    navigate('/notes');
  };
  

  const columns = [
    {
      title: "Content",
      dataIndex: "content",
      key: "content",
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      render: (customer) =>
        customer ? `${customer.first_name} ${customer.last_name}` : "N/A",
    },
    {
      title: "Due Date",
      dataIndex: "due_date",
      key: "due_date",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  const filterOption = (input, option) => {
    // Ensure children is a string before calling toLowerCase
    const childrenString = option.children.toString();
    return childrenString.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  const handleCustomerChange = (value) => {
    setSelectedCustomerId(value);
  };

  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <div style={{ flex: 1, overflow: "auto" }}>
        <Title level={2} style={{ margin: "16px" }}>
          Notes
        </Title>
        <Button
          type="primary"
          onClick={handleCreateNote}
          style={{ margin: "16px" }}
        >
          Create Note
        </Button>
        <Table
          dataSource={notes}
          columns={columns}
          rowKey="id"
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
          })}
        />
      </div>
      {(selectedNote || isCreating) && (
        <Card
          style={{ width: 300, padding: "20px" }}
          title={isCreating ? "Create Note" : "Edit Note"}
          extra={
            <Button
              onClick={() => {
                setSelectedNote(null);
                setIsCreating(false);
                navigate("/notes");
              }}
            >
              Close
            </Button>
          }
        >
          <Form form={form} onFinish={handleSubmit} layout="vertical">
            <Form.Item name="customer_id" label="Customer">
              <div style={{ display: "flex", alignItems: "center" }}>
                 <Select
                  showSearch
                  placeholder="Select a customer"
                  optionFilterProp="children"
                  filterOption={filterOption}
                  onChange={handleCustomerChange}
                  allowClear
                  style={{ flex: 1 }}
                  value={selectedCustomerId}
                >
                  {customers.map((customer) => (
                    <Option key={customer.id} value={customer.id}>
                      {customer.first_name} {customer.last_name}
                    </Option>
                  ))}
                </Select>
                {form.getFieldValue("customer_id") && (
                  <Button
                    type="link"
                    onClick={() =>
                      navigate(
                        `/customers/${form.getFieldValue("customer_id")}`
                      )
                    }
                    icon={<FormOutlined />}
                  />
                )}
              </div>
            </Form.Item>
            <Form.Item name="due_date" label="Due Date">
              <DatePicker />
            </Form.Item>
            <Form.Item
              name="status"
              label="Status"
              rules={[{ required: true, message: "Please select a status!" }]}
            >
              <Select>
                <Option value="open">Open</Option>
                <Option value="completed">Completed</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="content"
              label="Content"
              rules={[{ required: true, message: "Please input content!" }]}
            >
              <TextArea rows={7} />
            </Form.Item>
            <Button type="primary" htmlType="submit">
              {isCreating ? "Create" : "Update"}
            </Button>
          </Form>
        </Card>
      )}
    </div>
  );
}

export default NotesPage;
