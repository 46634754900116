import React, { useState, useContext } from 'react';
import { Button, Form, Input } from 'antd';
import { GlobalContext } from '../GlobalContext';
import { useNavigate } from 'react-router-dom';
import { registerUser } from '../services/apiService'; // Import the registerUser function

function RegisterPage() {
  const { setToken } = useContext(GlobalContext);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    password_confirmation: ''
  });

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      const data = await registerUser(formData);
      console.log(data);
      setToken(data.token); // Save the token to the global context

      // Redirect the user to the dashboard
      navigate('/dashboard');

    } catch (error) {
      console.error('Registration failed:', error);
      // Handle registration error (e.g., show an error message to the user)
    }
  };

  return (
    <Form onFinish={handleSubmit}>
      <Form.Item label="Name">
        <Input name="name" value={formData.name} onChange={handleInputChange} />
      </Form.Item>
      <Form.Item label="Email">
        <Input name="email" value={formData.email} onChange={handleInputChange} />
      </Form.Item>
      <Form.Item label="Password">
        <Input.Password name="password" value={formData.password} onChange={handleInputChange} />
      </Form.Item>
      <Form.Item label="Confirm Password">
        <Input.Password name="password_confirmation" value={formData.password_confirmation} onChange={handleInputChange} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Register
        </Button>
      </Form.Item>
    </Form>
  );
}

export default RegisterPage;
