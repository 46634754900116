import React, { useState, useContext } from 'react';
import { Button, Form, Input } from 'antd';
import { loginUser } from '../services/apiService'; // Import from apiService.js
import { GlobalContext } from '../GlobalContext';
import { useNavigate } from 'react-router-dom';

function LoginPage() {
  const { setToken } = useContext(GlobalContext);
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      const data = await loginUser(formData);
      console.log(data);
      setToken(data.token); // Save the token to the global context

      // Redirect the user to the dashboard
      navigate('/dashboard');

    } catch (error) {
      console.error('Login failed:', error);
      // Handle login error (e.g., show an error message to the user)
    }
  };

  return (
    <Form onFinish={handleSubmit}>
      <Form.Item label="Email">
        <Input name="email" value={formData.email} onChange={handleInputChange} />
      </Form.Item>
      <Form.Item label="Password">
        <Input.Password name="password" value={formData.password} onChange={handleInputChange} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Login
        </Button>
      </Form.Item>
    </Form>
  );
}

export default LoginPage;
