//apiService.js
import axios from "axios";

//const API_URL = "http://127.0.0.1:8000/api";
const API_URL = "https://matzo-api.jobase.io/api";


const HEADERS = {
  "Content-Type": "application/json",
};

const api = axios.create({
  baseURL: API_URL,
  headers: HEADERS,
});

const handleApiCall = async (apiFunc, ...params) => {
  try {
    console.log("API Request:", { method: apiFunc.name, params });
    const response = await apiFunc(...params);
    console.log("API Response:", response.data);
    return response.data;
  } catch (error) {
    console.error("API Error:", error);
    console.error("Failed Request:", { method: apiFunc.name, params });
    throw error;
  }
};


// User-related functions
export const registerUser = (userData) => handleApiCall(api.post, "/register", userData);
export const loginUser = (credentials) => handleApiCall(api.post, "/login", credentials);


// Bakery-related functions
export const fetchBakeriesForUser = (token) => {
  const headers = { Authorization: `Bearer ${token}` };
  return handleApiCall(api.get, "/bakeries/user", { headers });
};

export const fetchBakingMethodsForBakery = async (bakeryId, token) => {
  const headers = { Authorization: `Bearer ${token}` };
  const response = await handleApiCall(api.get, `/baking-method/bakery/${bakeryId}`, { headers });
  return response;
};

export const fetchFlourTypesForBakery = async (bakeryId, token) => {
  const headers = { Authorization: `Bearer ${token}` };
  const response = await handleApiCall(api.get, `/flour-type/bakery/${bakeryId}`, { headers });
  return response;
};

export const fetchWorkersForBakery = async (bakeryId, token) => {
  const headers = { Authorization: `Bearer ${token}` };
  const response = await handleApiCall(api.get, `/bakeries/${bakeryId}/workers`, { headers });
  return response.workers;
};

export const createWorker = (workerData, token) => {
  const headers = { Authorization: `Bearer ${token}` };
  return handleApiCall(api.post, "/worker", workerData, { headers });
};

export const addShiftForBakery = (shiftData, token) => {
  const headers = { Authorization: `Bearer ${token}` };
  return handleApiCall(api.post, "/shifts", shiftData, { headers });
};

export const fetchShiftsForBakeryOnDate = (bakeryId, date, token) => {
  const headers = { Authorization: `Bearer ${token}` };
  return handleApiCall(api.get, `/bakeries/${bakeryId}/shifts/${date}`, { headers });
};

export const fetchShiftDetails = (shiftId, token) => {
  const headers = { Authorization: `Bearer ${token}` };
  return handleApiCall(api.get, `/shifts/${shiftId}`, { headers });
};

export const updateLapDetails = (lapId, data, token) => {
  const headers = { Authorization: `Bearer ${token}` };
  return handleApiCall(api.put, `/lap/${lapId}/`, data, { headers });
};

export const addLapToShift = (shiftId, timeStarted, token) => {
  const headers = { Authorization: `Bearer ${token}` };
  const data = { shift_id: shiftId, time_started: timeStarted };
  return handleApiCall(api.post, "/lap", data, { headers });
};

export const updateWorkerLapDetails = (lapId, workerId, value, token) => {
  const headers = { Authorization: `Bearer ${token}` };
  const data = {
    worker_id: workerId,
    number_of_pieces: value,
  };
  return handleApiCall(api.post, `/lap/${lapId}/workers`, data, { headers });
};

export const fetchShiftsReport = (startDate, endDate, bakeryId) => {
  const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  const params = { start_date: startDate, end_date: endDate, bakery_id: bakeryId };
  return handleApiCall(api.get, `/shifts-report`, { headers, params });
};

export const addWorkerToShift = (shiftId, workerId, token) => {
  const headers = { Authorization: `Bearer ${token}` };
  const data = { worker_id: workerId };
  return handleApiCall(api.post, `/shifts/${shiftId}/workers`, data, { headers });
};

export const fetchWorkerReport = (workerId, startDate, endDate, token) => {
  const headers = { Authorization: `Bearer ${token}` };
  const params = { start_date: startDate, end_date: endDate };
  return handleApiCall(api.get, `/worker-report/worker/${workerId}`, { headers, params });
};

export const fetchCustomersForBakery = async (bakeryId, token) => {
  const headers = { Authorization: `Bearer ${token}` };
  return handleApiCall(api.get, `/customers/bakery/${bakeryId}`, { headers });
};

export const fetchCustomerDetails = async (customerId, token) => {
  const headers = { Authorization: `Bearer ${token}` };
  return handleApiCall(api.get, `/customers/${customerId}`, { headers });
};

export const createCustomer = (firstName, lastName, phoneNumber, bakeryId, token) => {
  const headers = { Authorization: `Bearer ${token}` };
  const data = {first_name: firstName, last_name: lastName, phone_number: phoneNumber, bakery_id: bakeryId};
  return handleApiCall(api.post, `/customers`, data, { headers });
};

export const updateCustomer = (customerId, firstName, lastName, phoneNumber, bakeryId, token) => {
  const headers = { Authorization: `Bearer ${token}` };
  const data = {first_name: firstName, last_name: lastName, phone_number: phoneNumber, bakery_id: bakeryId};
  return handleApiCall(api.put, `/customers/${customerId}`, data, { headers });
};

export const fetchAppointmentsForBakery = async (bakeryId, token) => {
  const headers = { Authorization: `Bearer ${token}` };
  return handleApiCall(api.get, `/bakeries/${bakeryId}/appointments`, { headers });
};

export const createAppointment = (customer_id, appointment_date_time, pounds, comments, baking_methods, flour_types, token) => {
  const headers = { Authorization: `Bearer ${token}` };
  const data = {customer_id: customer_id, appointment_date_time: appointment_date_time, pounds: pounds, comments: comments, baking_methods: baking_methods, flour_types: flour_types};
  return handleApiCall(api.post, `/appointments`, data, { headers });
};

export const updateAppointment = (appointment_id, customer_id, appointment_date_time, pounds, comments, baking_methods, flour_types, token) => {
  const headers = { Authorization: `Bearer ${token}` };
  const data = {customer_id: customer_id, appointment_date_time: appointment_date_time, pounds: pounds, comments: comments, baking_methods: baking_methods, flour_types: flour_types};
  return handleApiCall(api.put, `/appointment/${appointment_id}`, data, { headers });
};

export const deleteAppointment = (appointment_id, token) => {
  const headers = { Authorization: `Bearer ${token}` };
  return handleApiCall(api.delete, `/appointment/${appointment_id}`, { headers });
};

// Shift Schedule-related functions
export const fetchShiftSchedulesForBakery = (bakeryId, token) => {
  const headers = { Authorization: `Bearer ${token}` };
  return handleApiCall(api.get, `/shift-schedules/by-bakery/${bakeryId}`, { headers });
};

export const createShiftSchedule = (shiftData, token) => {
  const headers = { Authorization: `Bearer ${token}` };
  return handleApiCall(api.post, "/shift-schedules", shiftData, { headers });
};

export const updateShiftSchedule = (shiftId, updatedShiftData, token) => {
  const headers = { Authorization: `Bearer ${token}` };
  return handleApiCall(api.put, `/shift-schedules/${shiftId}`, updatedShiftData, { headers });
};


// Fetch notes for a specific bakery
export const fetchNotesForBakery = (bakeryId, token) => {
  const headers = { Authorization: `Bearer ${token}` };
  return handleApiCall(api.get, `/notes/by-bakery/${bakeryId}`, { headers });
};

// Fetch notes for a specific customer
export const fetchNotesForCustomer = (customerId, token) => {
  const headers = { Authorization: `Bearer ${token}` };
  return handleApiCall(api.get, `/notes/customer/${customerId}`, { headers });
};

// Create a new note
export const createNote = (noteData, token) => {
  const headers = { Authorization: `Bearer ${token}` };
  return handleApiCall(api.post, `/notes`, noteData, { headers });
};

// Update an existing note
export const updateNote = (noteId, noteData, token) => {
  const headers = { Authorization: `Bearer ${token}` };
  return handleApiCall(api.put, `/notes/${noteId}`, noteData, { headers });
};

// Delete a note
export const deleteNote = (noteId, token) => {
  const headers = { Authorization: `Bearer ${token}` };
  return handleApiCall(api.delete, `/notes/${noteId}`, { headers });
};

// Fetch details for a specific note
export const fetchNoteDetails = (noteId, token) => {
  const headers = { Authorization: `Bearer ${token}` };
  return handleApiCall(api.get, `/notes/${noteId}`, { headers });
};