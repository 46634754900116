import { useState, useEffect } from "react";
import {
  fetchAppointmentsForBakery,
  fetchCustomersForBakery,
  fetchBakingMethodsForBakery,
  fetchFlourTypesForBakery,
} from "../services/apiService";

export const useBakeryData = (selectedBakery, token) => {
  const [appointments, setAppointments] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [availableBakingMethods, setAvailableBakingMethods] = useState([]);
  const [availableFlourTypes, setAvailableFlourTypes] = useState([]);

  useEffect(() => {
    if (selectedBakery && token) {
      const fetchData = async () => {
        const appointmentData = await fetchAppointmentsForBakery(selectedBakery.id, token);
        const customerData = await fetchCustomersForBakery(selectedBakery.id, token);
        const bakingMethods = await fetchBakingMethodsForBakery(selectedBakery.id, token);
        const flourTypes = await fetchFlourTypesForBakery(selectedBakery.id, token);

        setAppointments(appointmentData);
        setCustomers(customerData);
        setAvailableBakingMethods(bakingMethods);
        setAvailableFlourTypes(flourTypes);
      };

      fetchData();
    }
  }, [selectedBakery, token]);

  return { appointments, setAppointments, customers, setCustomers, availableBakingMethods, availableFlourTypes };
};
