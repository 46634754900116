import React from "react";
import { Typography } from "antd";

const { Title, Paragraph } = Typography;

function VersionHistoryPage() {
  return (
    <div>
      <Title level={2}>Version History</Title>
      <Paragraph>
        <strong>Version 0.1.28</strong>
        <br />- Updated API to https.
      </Paragraph>
      <Paragraph>
        <strong>Version 0.1.27</strong>
        <br />- Can now select multiple slots in the calendar to create multiple
        appointments at the same time.
      </Paragraph>
      <Paragraph>
        <strong>Version 0.1.26</strong>
        <br />- Can add and edit notes in the customer page.
      </Paragraph>
      <Paragraph>
        <strong>Version 0.1.25</strong>
        <br />- Showing notes in the customer details, in the customer page.
      </Paragraph>
      <Paragraph>
        <strong>Version 0.1.24</strong>
        <br />- Added version history page
      </Paragraph>
      <Paragraph>
        <strong>Version 0.1.23</strong>
        <br />- Added notes page.
      </Paragraph>
      {/* Add more version details here */}
    </div>
  );
}

export default VersionHistoryPage;
