import React, { useState } from 'react';
import { InputNumber, Select, Button, Space, Form, Row, Col, Collapse, Input } from 'antd';
import moment from 'moment';
import groupBy from 'lodash/groupBy';

const { Panel } = Collapse;
const { Option } = Select;

const BakingPlanPage = () => {
  const [data, setData] = useState([
    {
      key: '1',
      date: '2023-10-01',
      shift: 'Morning',
      flourType: 'All-purpose',
      bakingMethod: 'Oven',
      pounds: 10,
    },
    {
      key: '2',
      date: '2023-10-01',
      shift: 'Evening',
      flourType: 'Whole Wheat',
      bakingMethod: 'Oven',
      pounds: 12,
    },
    {
      key: '3',
      date: '2023-10-02',
      shift: 'Morning',
      flourType: 'All-purpose',
      bakingMethod: 'Oven',
      pounds: 15,
    },
    {
      key: '4',
      date: '2023-10-02',
      shift: 'Evening',
      flourType: 'Whole Wheat',
      bakingMethod: 'Oven',
      pounds: 18,
    },
    {
        key: '5',
        date: '2023-10-02',
        shift: 'Evening',
        flourType: 'Whole Wheat',
        bakingMethod: 'Oven',
        pounds: 18,
      },
      {
        key: '6',
        date: '2023-10-20',
        shift: 'Morning',
        flourType: 'All-purpose',
        bakingMethod: 'Oven',
        pounds: 15,
      },
      {
        key: '7',
        date: '2023-10-20',
        shift: 'Evening',
        flourType: 'Whole Wheat',
        bakingMethod: 'Oven',
        pounds: 18,
      },
      {
          key: '8',
          date: '2023-10-20',
          shift: 'Evening',
          flourType: 'Whole Wheat',
          bakingMethod: 'Oven',
          pounds: 18,
        },
        {
            key: '9',
            date: '2023-10-20',
            shift: 'Evening',
            flourType: 'Whole Wheat',
            bakingMethod: 'Oven',
            pounds: 18,
          },
    // Add more dummy data here
  ]);

  const groupedByDay = groupBy(data, 'date');
  const groupedByWeek = groupBy(data, (item) => moment(item.date).week());

  const columns = [
    {
      title: 'Shift',
      dataIndex: 'shift',
      key: 'shift',
    },
    {
      title: 'Flour Type',
      dataIndex: 'flourType',
      key: 'flourType',
      render: (text, record) => (
        <Form.Item name={[record.key, 'flourType']} initialValue={text}>
          <Select>
            <Option value="All-purpose">All-purpose</Option>
            <Option value="Whole Wheat">Whole Wheat</Option>
          </Select>
        </Form.Item>
      ),
    },
    {
      title: 'Baking Method',
      dataIndex: 'bakingMethod',
      key: 'bakingMethod',
      render: (text, record) => (
        <Form.Item name={[record.key, 'bakingMethod']} initialValue={text}>
          <Select>
            <Option value="Oven">Oven</Option>
            <Option value="Stove">Stove</Option>
          </Select>
        </Form.Item>
      ),
    },
    {
      title: 'Pounds',
      dataIndex: 'pounds',
      key: 'pounds',
      render: (text, record) => (
        <Form.Item name={[record.key, 'pounds']} initialValue={text}>
          <InputNumber />
        </Form.Item>
      ),
    },
  ];

  const [form] = Form.useForm();

  const onFinish = (values) => {
    // Update data based on form values
    // Implement your logic here
  };
    // Implement your logic here

    return (
        <div>
          <h1>Baking Plan</h1>
          <Space style={{ marginBottom: 16 }}>
            <Button type="primary" onClick={() => form.submit()}>
              Edit Selected Rows
            </Button>
            <Button type="default">Stats by Week</Button>
            <Button type="default">Stats by Month</Button>
            <Button type="default">Stats by Year</Button>
          </Space>
    
          <Form form={form} onFinish={onFinish}>
            <Collapse>
              {Object.keys(groupedByWeek).map((week) => (
                <Panel header={`Week ${week}`} key={week}>
                  {Object.keys(groupedByDay)
                    .filter((day) => moment(day).week() === parseInt(week))
                    .map((day) => (
                      <div key={day}>
                        <h3>{moment(day).format('YYYY-MM-DD')}</h3>
                        <Row gutter={[16, 16]}>
                          {groupedByDay[day].map((shift) => (
                            <Col key={shift.key} span={6}>
                              <div style={{ border: '1px solid #ccc', padding: '10px', display: 'flex', justifyContent: 'space-between' }}>
                              <div style={{ fontSize: '10px', position: 'absolute', top: '-10px', left: '5px', background: '#fff', padding: '0 2px' }}>
                                  {`9:00 AM-11:30 AM | Laps: 10`}
                                </div>
                                <Form.Item name={[shift.key, 'flourType']} initialValue={shift.flourType} style={{ margin: 0 }}>
                                  <Select>
                                    <Option value="All-purpose">All-purpose</Option>
                                    <Option value="Whole Wheat">Whole Wheat</Option>
                                  </Select>
                                </Form.Item>
                                <Form.Item name={[shift.key, 'bakingMethod']} initialValue={shift.bakingMethod} style={{ margin: 0 }}>
                                  <Select>
                                    <Option value="Oven">Oven</Option>
                                    <Option value="Stove">Stove</Option>
                                  </Select>
                                </Form.Item>
                                <Form.Item name={[shift.key, 'pounds']} initialValue={shift.pounds} style={{ margin: 0 }}>
                                  <Input />
                                </Form.Item>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      </div>
                    ))}
                </Panel>
              ))}
            </Collapse>
          </Form>
        </div>
      );
    };
    
    export default BakingPlanPage;