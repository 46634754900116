import React from 'react';
import { useState, useCallback } from 'react';
import { Table, Tooltip, Button, Input, Divider } from 'antd';
import AddWorkerToShift from './AddWorkerToShift';
import { ReactGrid, Column, Row } from '@silevis/reactgrid';
import '@silevis/reactgrid/styles.css';
import { ClickableHeaderCellTemplate } from '../ReactTableCells/ClickableHeaderCell.tsx';



function ShiftTable({ shifts, handleLapClick, handleEdit, editingKey, handleSave, handleAddLap, allWorkers, loadShiftDetails }) {

    const generateColumns = (laps, shift) => {
        let columns = [
          {
            title: 'Worker Name',
            dataIndex: 'name',
            key: 'name'
          }
        ];
        laps.forEach(lap => {
          columns.push({
            title: (
              <Tooltip title="Click to view/edit lap details">
                <Button type="link" onClick={() => handleLapClick(lap)}>
                  {lap.time_started || '00:00'}
                </Button>
              </Tooltip>
            ),
            dataIndex: `lap_${lap.id}`,
            key: `lap_${lap.id}`,
            render: (text, record) => {
              const editable = editingKey === `${record.key}_${lap.id}`;
              return editable ? (
                <Input 
                  defaultValue={text} 
                  onPressEnter={(e) => handleSave(record.key, lap.id, e.target.value)}
                  onBlur={(e) => handleSave(record.key, lap.id, e.target.value)} 
                />
              ) : (
                <div onClick={() => handleEdit(record.key, lap.id)}>
                  {text || 'Add Number'}
                </div>
              );
            }
          });
        });
        columns.push({
          title: (
            <Button type="primary" onClick={() => handleAddLap(shift.id, shift)}>
              Add New Lap
            </Button>
          ),
          dataIndex: 'add_lap',
          key: 'add_lap'
        });
        return columns;
      };
    
      const generateDataSource = (laps) => {
        let dataSource = [];
        laps.forEach(lap => {
          lap.workers.forEach(worker => {
            const existingWorker = dataSource.find(w => w.key === worker.worker_id);
            if (existingWorker) {
              existingWorker[`lap_${lap.id}`] = worker.number_of_pieces;
            } else {
              dataSource.push({
                key: worker.worker_id,
                name: worker.name,
                [`lap_${lap.id}`]: worker.number_of_pieces,
              });
            }
          });
        });
        return dataSource;
      };
    

// New functions for ReactGrid table:


const getData = (laps) => {
  let data = [];
  laps.forEach(lap => {
    lap.workers.forEach(worker => {
      const existingWorker = data.find(d => d.worker_id === worker.worker_id);
      const numberOfPieces = worker.number_of_pieces ? worker.number_of_pieces : "";  // Set to 0 if not available
      if (existingWorker) {
        existingWorker[`lap_${lap.id}`] = numberOfPieces;
      } else {
        data.push({
          worker_id: worker.worker_id,
          name: worker.name,
          [`lap_${lap.id}`]: numberOfPieces,
        });
      }
    });
  });
  return data;
};



const getColumns = (laps) => [
  { columnId: "name", width: 150 },
  ...laps.map(lap => ({ columnId: `lap_${lap.id}`, width: 50 })),
];

const getHeaderRow = (laps) => ({
  rowId: "header",
  cells: [
    { type: "header", text: "Name" },
    ...laps.map(lap => ({ type: "clickableHeader", text: `${lap.time_started}`, lap: lap, nonEditable: true  })),
  ]
});


const getRows = (data, headerRow) => [
  headerRow,
  ...data.map((rowData, idx) => ({
    rowId: rowData.worker_id.toString(),
    cells: [
      { type: 'header', text: rowData.name, nonEditable: true },
      ...Object.keys(rowData).filter(key => key !== 'name' && key !== 'worker_id').map(key => ({
        type: 'text', text: rowData[key] ? rowData[key].toString() : ''  // Set to '0' if not available
      })),
    ]
  }))
];

const handleCellChange = useCallback((changes) => {
  console.log(changes);
    changes.forEach(change => {
      const { rowId, columnId } = change;
      const newText = change.newCell.text;  // Extract newText from change.newCell.text
      // Find the corresponding lap id and worker id from the rowId and columnId
      const lapId = parseInt(columnId.split('_')[1], 10);
      const workerId = parseInt(rowId, 10);

      // Call your existing handleSave function
      handleSave(workerId, lapId, newText);
    });
  }, [handleSave]);


return (
  <>
    {shifts.map((shift, index) => {
      const data = getData(shift.laps, allWorkers);
      console.log(shifts)
      const dgcolumns = getColumns(shift.laps);
      const headerRow = getHeaderRow(shift.laps);
      const dgrows = getRows(data, headerRow);

      return (
        <div key={index}>
          <Divider>{`Shift ${index+1} - ${shift.start_time} to ${shift.end_time}`}</Divider>
          <Table 
            dataSource={generateDataSource(shift.laps)} 
            columns={generateColumns(shift.laps, shift)} 
          />
          <div style={{ marginTop: "-60px", marginBottom: "50px" }}>
            <AddWorkerToShift 
              shiftId={shift.id} 
              workers={allWorkers.filter(worker => !shift.laps.some(lap => lap.workers.some(w => w.worker_id === worker.id)))} 
              onWorkerAdded={loadShiftDetails} 
            />
          </div>
          <div style={{ margin: '0 auto', width: '80%', height: '500px', overflow: 'auto' }}>
            <ReactGrid 
              rows={dgrows} 
              columns={dgcolumns} 
              stickyTopRows={1}
              stickyLeftColumns={1}
              onCellsChanged={handleCellChange} 
              customCellTemplates={{ clickableHeader: new ClickableHeaderCellTemplate(handleLapClick) }}
              enableFillHandle
            />
          </div>
        </div>
      );
    })}
  </>
);
}

export default ShiftTable;