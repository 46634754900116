import React, { useState, useContext } from 'react'; // Import useContext
import { Select, Button } from 'antd';
import { addWorkerToShift } from '../services/apiService';
import { GlobalContext } from '../GlobalContext'; // Import GlobalContext

const { Option } = Select;

function AddWorkerToShift({ shiftId, workers, onWorkerAdded }) {
  const [selectedWorker, setSelectedWorker] = useState(null);
  const { token } = useContext(GlobalContext); // Use the token from the GlobalContext

  const handleAddWorker = async () => {
    if (selectedWorker) {
      try {
        await addWorkerToShift(shiftId, { worker_id: selectedWorker }, token); // Use the token from context
        onWorkerAdded(); // Notify parent component to refresh the data
      } catch (error) {
        console.error("Error adding worker to shift:", error);
      }
    }
  };

  return (
    <div>
      <Select
        placeholder="Select a worker"
        onChange={(value) => setSelectedWorker(value)}
        style={{ width: 200 }}
      >
        {workers.map(worker => (
          <Option key={worker.id} value={worker.id}>
            {worker.first_name} {worker.last_name}
          </Option>
        ))}
      </Select>
      <Button type="primary" onClick={handleAddWorker}>
        Add Worker
      </Button>
    </div>
  );
}

export default AddWorkerToShift;
