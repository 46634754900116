import React from 'react';

function WorkerReportsPage() {
  return (
    <div>
      <h1>Worker Reports</h1>
      {/* Your content here */}
    </div>
  );
}

export default WorkerReportsPage;
