import React, { useContext } from "react";
import { Menu, Layout, Dropdown, Button, Switch } from "antd";
import { Link } from "react-router-dom";
import { GlobalContext } from "../GlobalContext";
import translations from "../translations";
import { DownOutlined, DashboardOutlined, ScheduleOutlined, UserOutlined, SettingOutlined, FileTextOutlined } from "@ant-design/icons";


const { Sider } = Layout;
const { SubMenu } = Menu; // Import SubMenu

function SideMenu() {
  const {
    bakeries,
    selectedBakery,
    setSelectedBakery,
    logout,
    language,
    setLanguage,
  } = useContext(GlobalContext);

  const dropdownMenu = (
    <Menu>
      {bakeries &&
        bakeries.map((bakery) => (
          <Menu.Item key={bakery.id} onClick={() => setSelectedBakery(bakery)}>
            {bakery.name}
          </Menu.Item>
        ))}
    </Menu>
  );

  const toggleLanguage = (checked) => {
    setLanguage(checked ? "yi" : "en");
  };

  return (
    <Sider width={200} className="site-layout-background">
      <Menu
        mode="vertical"
        defaultSelectedKeys={["1"]}
        style={{
          height: "100%",
          borderRight: 0,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Menu.Item key="dropdown">
          <Dropdown overlay={dropdownMenu} trigger={["click"]}>
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              {selectedBakery ? selectedBakery.name : "Select a Bakery"}{" "}
              <DownOutlined />
            </a>
          </Dropdown>
        </Menu.Item>
        <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
  <Link to="/dashboard">Dashboard</Link>
</Menu.Item>

<Menu.Item key="appointments" icon={<ScheduleOutlined />}>
  <Link to="/appointments">{translations[language].appointments}</Link>
</Menu.Item>

<Menu.Item key="schedule" icon={<ScheduleOutlined />}>
  <Link to="/schedule">Schedule</Link>
</Menu.Item>

<Menu.Item key="customers" icon={<UserOutlined />}>
  <Link to="/customers">{translations[language].customers}</Link>
</Menu.Item>

<Menu.Item key="notes" icon={<FileTextOutlined />}>
  <Link to="/notes">Notes</Link>
</Menu.Item>



<SubMenu key="settings" title="Settings" icon={<SettingOutlined />}>
  <Menu.Item key="shiftSchedule">
    <Link to="/shiftschedule">Shift Schedule</Link>
  </Menu.Item>
  <Menu.Item key="bakingPlan">
    <Link to="/bakingplan">Baking Plan</Link>
  </Menu.Item>
</SubMenu>


        <SubMenu key="inprogress" title="Upcoming Features">
        <Menu.Item key="workers">
          <Link to="/workers">{translations[language].workers}</Link>
        </Menu.Item>
        <Menu.Item key="matzocount">
          <Link to="/matzocount">Matzo Counting</Link>
        </Menu.Item>
        <Menu.Item key="weeklyReports">
            <Link to="/reports/weekly">Weekly Reports</Link>
          </Menu.Item>
          <Menu.Item key="workerReports">
            <Link to="/reports/worker">Worker Reports</Link>
          </Menu.Item>
        </SubMenu>
        

        
        <div style={{ marginTop: "auto" }}>
          <div style={{ marginBottom: "10px" }}>
          
          <br />
            <Switch
              checked={language === "yi"}
              onChange={toggleLanguage}
              checkedChildren="Yiddish"
              unCheckedChildren="English"
            />
          </div>
          <Link to="/versionhistory">version 0.1.27</Link>
          <br />
          <Button onClick={logout}  style={{margin:20}}>
            Logout
          </Button>
        </div>
      </Menu>
    </Sider>
  );
}

export default SideMenu;
