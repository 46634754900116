//GlobalContext.js
import React, { createContext, useState, useEffect } from 'react';
import { fetchBakeriesForUser } from './services/apiService';

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [bakeries, setBakeries] = useState([]);
  const [selectedBakery, setSelectedBakery] = useState(() => {
    const storedBakery = localStorage.getItem('selectedBakery');
    return storedBakery ? JSON.parse(storedBakery) : null;
  });
  const [token, setTokenState] = useState(localStorage.getItem('userToken') || null);

  const setAndStoreToken = (newToken) => {
    setTokenState(newToken);
    if (newToken) {
      localStorage.setItem('userToken', newToken);
    } else {
      localStorage.removeItem('userToken');
    }
  };

  const logout = () => {
    setBakeries([]);
    setSelectedBakery(null); // This will also trigger the useEffect to remove the selectedBakery from localStorage
    setAndStoreToken(null); // This will also remove the token from localStorage
    window.location.href = '/';
  };

  const [language, setLanguage] = useState(localStorage.getItem('language') || 'en');

  const setAndStoreLanguage = (newLanguage) => {
    setLanguage(newLanguage);
    localStorage.setItem('language', newLanguage);
  };

  useEffect(() => {
    console.log("Token updated:", token);

    if (token) {
      const loadBakeries = async () => {
        try {
          const data = await fetchBakeriesForUser(token);
          setBakeries(data);
        } catch (error) {
          console.error("Error fetching bakeries:", error);
        }
      };

      loadBakeries();
    }
  }, [token]);

  useEffect(() => {
    if (selectedBakery) {
      localStorage.setItem('selectedBakery', JSON.stringify(selectedBakery));
    } else {
      localStorage.removeItem('selectedBakery');
    }
  }, [selectedBakery]);

  return (
    <GlobalContext.Provider value={{
      bakeries, 
      setBakeries, 
      selectedBakery, 
      setSelectedBakery, 
      token, 
      setToken: setAndStoreToken, 
      logout,
      language,
      setLanguage: setAndStoreLanguage, 
    }}>
      {children}
    </GlobalContext.Provider>
  );
};
