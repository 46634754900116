import React, { useContext, useState, useEffect } from "react";
import { Button, Modal, Form, Input, DatePicker } from "antd";
import moment from "moment";
import { GlobalContext } from "../GlobalContext";
import {
  addShiftForBakery,
  updateWorkerLapDetails,
  addLapToShift,
  updateLapDetails,
  fetchShiftsForBakeryOnDate,
  fetchWorkersForBakery,
} from "../services/apiService";
import LapDetailsModal from "../components/LapDetailsModal";
import ShiftTable from "../components/ShiftTable";

function MoatzoCountPage() {
  const { selectedBakery, token } = useContext(GlobalContext);
  const [shifts, setShifts] = useState([]);
  const [workers, setWorkers] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [lapDetailsModalVisible, setLapDetailsModalVisible] = useState(false);
  const [selectedLap, setSelectedLap] = useState(null);
  const [formData, setFormData] = useState({});
  const [selectedDate, setSelectedDate] = useState(moment());
  const [editingKey, setEditingKey] = useState("");
  

  useEffect(() => {
    if (selectedBakery && token) {
      loadShiftDetails();
      loadWorkers();
    }
  }, [selectedBakery, token, selectedDate]);

  const loadShiftDetails = async () => {
    try {
      const formattedDate = selectedDate.format("YYYY-MM-DD");
      const fetchedShifts = await fetchShiftsForBakeryOnDate(
        selectedBakery.id,
        formattedDate,
        token
      );
      setShifts(fetchedShifts);
    } catch (error) {
      console.error("Error fetching shift details:", error);
    }
  };

  const loadWorkers = async () => {
    //log token
    //console.log("shifts age "+token);
    try {
      const fetchedWorkers = await fetchWorkersForBakery(
        selectedBakery.id,
        token
      );
      setWorkers(fetchedWorkers);
    } catch (error) {
      console.error("Error fetching workers:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      formData.bakery_id = selectedBakery.id;
      formData.date = selectedDate.format("YYYY-MM-DD");
      await addShiftForBakery(formData, token);
      setIsModalVisible(false);
      loadShiftDetails();
    } catch (error) {
      console.error("Error adding shift:", error);
    }
  };

  const getNextLapStartTime = (shift) => {
    if (!shift.laps.length) {
      return moment(shift.start_time, "HH:mm").format("HH:mm");
    }
    const lastLapTime = moment(
      shift.laps[shift.laps.length - 1].time_started,
      "HH:mm"
    );
    return lastLapTime.add(15, "minutes").format("HH:mm");
};

  const handleAddLap = async (shiftId, shift) => {
    if (!shiftId) {
      console.error("Shift ID is not available.");
      return;
    }

    const timeStarted = getNextLapStartTime(shift);
    try {
      await addLapToShift(shiftId, timeStarted, token);
      loadShiftDetails();
    } catch (error) {
      console.error("Error adding lap:", error);
    }
  };

  const handleLapClick = (lap) => {
    console.log("lap", lap);
    setSelectedLap(lap);
    setLapDetailsModalVisible(true);
  };

  const handleLapDetailsChange = (field, value) => {
    setSelectedLap((prev) => ({ ...prev, [field]: value }));
  };

  const handleUpdateLapDetails = async () => {
    try {
      await updateLapDetails(selectedLap.id, selectedLap, token);
      setLapDetailsModalVisible(false);
      loadShiftDetails();
    } catch (error) {
      console.error("Error updating lap details:", error);
    }
  };

  const handleEdit = (workerId, lapId) => {
    setEditingKey(`${workerId}_${lapId}`);
  };

  const handleSave = async (workerId, lapId, value) => {
    if (value) {
      try {
        await updateWorkerLapDetails(lapId, workerId, value, token);
        setEditingKey(""); // Reset editing key after saving
        loadShiftDetails(); // Reload data after saving
      } catch (error) {
        console.error("Error updating worker data:", error);
      }
    }
  };

  return (
    <div>
      <DatePicker
        onChange={(date) => setSelectedDate(date)}
        defaultValue={selectedDate}
      />
      <Button type="primary" onClick={() => setIsModalVisible(true)}>
        Add New Shift
      </Button>

      <ShiftTable
        shifts={shifts}
        handleLapClick={handleLapClick}
        handleEdit={handleEdit}
        editingKey={editingKey}
        handleSave={handleSave}
        handleAddLap={handleAddLap}
        allWorkers={workers}
        loadShiftDetails={loadShiftDetails}
      />

      <Modal
        title="Add New Shift"
        visible={isModalVisible}
        onOk={handleSubmit}
        onCancel={() => setIsModalVisible(false)}
      >
        <Form>
          <Form.Item label="Start Time">
            <Input type="time" name="start_time" onChange={handleInputChange} />
          </Form.Item>
          <Form.Item label="End Time">
            <Input type="time" name="end_time" onChange={handleInputChange} />
          </Form.Item>
        </Form>
      </Modal>

      <LapDetailsModal
        isVisible={lapDetailsModalVisible}
        lap={selectedLap}
        onOk={handleUpdateLapDetails}
        onCancel={() => setLapDetailsModalVisible(false)}
        onChange={handleLapDetailsChange}
      />
    </div>
  );
}

export default MoatzoCountPage;
