import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { Layout } from 'antd';
import { GlobalProvider } from './GlobalContext';
import { GlobalContext } from './GlobalContext';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import WorkersPage from "./pages/WorkersPage";
import Dashboard from "./pages/Dashboard";
import SideMenu from "./components/SideMenu";
import LandingPage from "./pages/LandingPage";
import MoatzoCountPage from "./pages/MoatzoCountPage";
import AppointmentsPage from "./pages/AppointmentsPage";
import SchedulePage from "./pages/SchedulePage";
import ShiftSchedulePage from "./pages/ShiftSchedulePage";
import BakingPlanPage from "./pages/BakingPlanPage";
import LapDetailsPage from "./pages/LapDetailsPage";
import CustomersPage from "./pages/CustomersPage";
import WeeklyReportsPage from "./pages/WeeklyReportsPage";
import WorkerReportsPage from "./pages/WorkerReportsPage";
import NotesPage from "./pages/NotesPage";
import VersionHistoryPage from "./pages/VersionHistoryPage";

const { Content } = Layout;

function AppContent() {
  const location = useLocation();
  const navigate = useNavigate();
  const { token } = useContext(GlobalContext);
  const pathsWithoutMenu = ["/", "/login", "/register"];

  useEffect(() => {
    if (token && location.pathname === "/") {
      navigate("/dashboard");
    }
  }, [token, location.pathname, navigate]);

 

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {!pathsWithoutMenu.includes(location.pathname) && <SideMenu />}
      <Layout>
        <Content style={{ padding: '0 50px' }}>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/workers" element={<WorkersPage />} />
            <Route path="/matzocount" element={<MoatzoCountPage />} />
            <Route path="/" element={<LandingPage />} />
            <Route path="/reports/weekly" element={<WeeklyReportsPage />} /> 
            <Route path="/reports/worker" element={<WorkerReportsPage />} /> 
            <Route path="/appointments" element={<AppointmentsPage />} />
            <Route path="/appointments/:appointmentId" element={<AppointmentsPage />} />
            <Route path="/schedule" element={<SchedulePage />} />
            <Route path="/shiftschedule" element={<ShiftSchedulePage />} />
            <Route path="/bakingplan" element={<BakingPlanPage />} />
            <Route path="/lapdetails" element={<LapDetailsPage />} />
            <Route path="/customers" element={<CustomersPage />} />
            <Route path="/customers/:customerId" element={<CustomersPage />} />
            <Route path="/notes" element={<NotesPage />} />
            <Route path="/notes/:noteId" element={<NotesPage />} />
            <Route path="/versionhistory" element={<VersionHistoryPage />} />
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
}

function App() {
  return (
    <GlobalProvider>
      <Router>
        <AppContent />
      </Router>
    </GlobalProvider>
  );
}

export default App;
