import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Select, Input, Form, Button } from "antd";
import translations from "../translations";
import { GlobalContext } from "../GlobalContext";
import { getHebrewWeekName, getHebrewDayLetter } from "../utils/dateUtils";
import { CloseCircleOutlined } from '@ant-design/icons';


const { Option } = Select;

const AppointmentForm = ({
  panelType,
  selectedDate,
  appointmentData,
  setAppointmentData,
  selectedDuration,
  customers,
  bakingMethods,
  setBakingMethods,
  availableBakingMethods,
  flourTypes,
  setFlourTypes,
  availableFlourTypes,
  newCustomer,
  setNewCustomer,
  saveAppointment,
  cancelAppointment,
  setPanelType,
  resetPanelState,
  navigate,
}) => {
  console.log("appintment data:", appointmentData);
  console.log("Cusappintment id:", appointmentData.id);
  console.log("Baking Methods State:", bakingMethods);
  console.log("Flour Types State:", flourTypes);
  console.log("Available Baking Methods:", availableBakingMethods);
  console.log("Available Flour Types:", availableFlourTypes);

  const [showNewCustomerFields, setShowNewCustomerFields] =
    React.useState(false);
  const { language } = useContext(GlobalContext); // Get language from GlobalContext
  const dateObject = new Date(selectedDate);
  const formattedDate = dateObject.toLocaleDateString("en-US");
  const formattedTime = dateObject.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  const dayOfWeekIndex = dateObject.getDay();
  const hebrewDayLetter = getHebrewDayLetter(dayOfWeekIndex); // Use the mapping function
  const hebrewWeekName = getHebrewWeekName(selectedDate); // Use the utility function

  return (
    <div style={{ overflowY: "auto", maxHeight: "calc(100vh - 100px)" }}>
      <Form layout="vertical">
        <Form.Item label="Selected Time">
          <span
            style={{ fontWeight: "bold" }}
          >{`${hebrewDayLetter} ${hebrewWeekName}`}</span>
          <br />
          <span>{`${formattedDate}, ${formattedTime}`}</span>
           <br />
          {/* Display total selected time */}
           {panelType === "create" && (
            <span>{`Total time: ${selectedDuration / 15} slots (${selectedDuration} minutes)`}</span>
          )}
        </Form.Item>
        {panelType === "edit" && (
          <Button
            type="danger"
            onClick={() => cancelAppointment(appointmentData.id)}
            style={{
              marginBottom: "10px",
              borderColor: "red", // Add a red border
              color: "red", // Set text color to red
            }}
            icon={<CloseCircleOutlined />} // Add the red 'X' icon
          >
            Cancel Appointment
          </Button>
        )}
        <Form.Item label="Customer">
          <Select
            showSearch
            placeholder="Select a customer"
            optionFilterProp="children"
            value={appointmentData.customer_id}
            onChange={(value) => {
              if (value === "add_new_customer") {
                setShowNewCustomerFields(true);
                setAppointmentData({
                  ...appointmentData,
                  customer_id: null,
                });
              } else {
                setShowNewCustomerFields(false);
                setAppointmentData({
                  ...appointmentData,
                  customer_id: value,
                });
              }
            }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value="add_new_customer">Add New Customer</Option>
            {customers.map((customer) => (
              <Option key={customer.id} value={customer.id}>
                {customer.first_name} {customer.last_name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {showNewCustomerFields && (
          <Form.Item label="Add New Customer">
            <Input.Group compact>
              <Input
                style={{ width: "30%" }}
                placeholder="First Name"
                value={newCustomer.first_name}
                onChange={(e) =>
                  setNewCustomer({
                    ...newCustomer,
                    first_name: e.target.value,
                  })
                }
              />
              <Input
                style={{ width: "30%" }}
                placeholder="Last Name"
                value={newCustomer.last_name}
                onChange={(e) =>
                  setNewCustomer({
                    ...newCustomer,
                    last_name: e.target.value,
                  })
                }
              />
              <Input
                style={{ width: "40%" }}
                placeholder="Phone Number"
                value={newCustomer.phone_number}
                onChange={(e) =>
                  setNewCustomer({
                    ...newCustomer,
                    phone_number: e.target.value,
                  })
                }
              />
            </Input.Group>
          </Form.Item>
        )}
        <Form.Item label={translations[language].bakingMethods}>
          <Select
            mode="multiple"
            placeholder="Select baking methods"
            value={bakingMethods}
            onChange={(values) => setBakingMethods(values)}
          >
            {availableBakingMethods.map((method) => (
              <Option key={method.id} value={method.id}>
                {method.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={translations[language].flourTypes}>
          <Select
            mode="multiple"
            placeholder="Select flour type"
            value={flourTypes}
            onChange={(values) => setFlourTypes(values)}
          >
            {availableFlourTypes.map((flour) => (
              <Option key={flour.id} value={flour.id}>
                {flour.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={translations[language].pound}>
          <Input
            placeholder="Pounds"
            value={appointmentData.pounds}
            onChange={(e) =>
              setAppointmentData({
                ...appointmentData,
                pounds: e.target.value,
              })
            }
          />
        </Form.Item>
        <Form.Item label="Comments">
          <Input.TextArea
            placeholder="Comments"
            value={appointmentData.comments}
            onChange={(e) =>
              setAppointmentData({
                ...appointmentData,
                comments: e.target.value,
              })
            }
          />
        </Form.Item>
        <Button type="primary" onClick={saveAppointment}>
          {panelType === "create"
            ? translations[language].add
            : translations[language].update}
        </Button>
        <Button
          style={{ marginLeft: "10px" }}
          onClick={() => {
            setPanelType(null);
            resetPanelState();
            navigate("/appointments"); // Navigate back to parent page
          }}
        >
          {translations[language].cancel}
        </Button>
      </Form>
    </div>
  );
};

export default AppointmentForm;
