import React, { useState, useEffect, useContext } from "react";
import {
  Table,
  Button,
  Modal,
  Input,
  Form,
  Row,
  Col,
  List,
  Card,
  Select,
  Typography,
  Tabs,
  DatePicker,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useParams, useNavigate } from "react-router-dom";
import { GlobalContext } from "../GlobalContext";
import translations from "../translations";
import moment from "moment";
import { getHebrewDayLetter, getHebrewWeekName } from "../utils/dateUtils";
import {
  fetchCustomersForBakery,
  createCustomer,
  fetchCustomerDetails,
  updateCustomer,
  fetchNotesForCustomer,
  createNote,
  updateNote,
  deleteNote,
} from "../services/apiService";

const { Title, Text } = Typography;

function CustomersPage() {
  const { selectedBakery, token, language } = useContext(GlobalContext);
  const [customers, setCustomers] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [formData, setFormData] = useState({});
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerNotes, setCustomerNotes] = useState([]);
  const [editingNote, setEditingNote] = useState(null); // State for the note being edited
  const { customerId } = useParams();
  const navigate = useNavigate();
  const { TabPane } = Tabs;
  const { TextArea } = Input;
  const { Option } = Select;

  useEffect(() => {
    if (selectedBakery) {
      const loadCustomers = async () => {
        const data = await fetchCustomersForBakery(selectedBakery.id, token);
        setCustomers(data);
        if (customerId) {
          const detailedCustomer = await fetchCustomerDetails(
            customerId,
            token
          );
          setSelectedCustomer(detailedCustomer);
        }
      };
      loadCustomers();
    }
  }, [selectedBakery, token, customerId]);

  useEffect(() => {
    if (selectedCustomer && token) {
      fetchNotesForCustomer(selectedCustomer.id, token)
        .then(setCustomerNotes)
        .catch((error) =>
          console.error("Error fetching customer notes:", error)
        );
    }
  }, [selectedCustomer, token]);

  const handleAddCustomer = async () => {
    await createCustomer(
      formData.firstName,
      formData.lastName,
      formData.phoneNumber,
      selectedBakery.id,
      token
    );
    setIsModalVisible(false);
    const data = await fetchCustomersForBakery(selectedBakery.id, token);
    setCustomers(data);
  };

  const handleEditCustomer = async () => {
    await updateCustomer(
      selectedCustomer.id,
      formData.firstName,
      formData.lastName,
      formData.phoneNumber,
      selectedBakery.id,
      token
    );
    setIsEditModalVisible(false);
    const data = await fetchCustomersForBakery(selectedBakery.id, token);
    setCustomers(data);
  };

  const handleRowClick = (record) => {
    setSelectedCustomer(record);
    navigate(`/customers/${record.id}`);
    fetchCustomerDetails(record.id, token)
      .then((detailedCustomer) => {
        setSelectedCustomer(detailedCustomer);
      })
      .catch((error) => {
        console.error("Error fetching detailed customer data:", error);
      });
  };

  const closePanel = () => {
    setSelectedCustomer(null);
    navigate("/customers");
  };

  // Function to refresh notes for the selected customer
  const refreshNotes = () => {
    if (selectedCustomer && token) {
      fetchNotesForCustomer(selectedCustomer.id, token)
        .then(setCustomerNotes)
        .catch((error) =>
          console.error("Error fetching customer notes:", error)
        );
    }
  };

  // Function to handle note creation
  const handleCreateNote = async (values) => {
    console.log("values", values);
    const formattedValues = {
      ...values,
      due_date: values.due_date ? values.due_date.format('YYYY-MM-DD') : null,
      customer_id: selectedCustomer.id,
      bakery_id: selectedBakery.id,
    };

    await createNote(formattedValues, token);
    setEditingNote(null);
    refreshNotes();
  };

  // Function to handle note update
  const handleUpdateNote = async (values) => {
    console.log("values", values);
    const formattedValues = {
      ...values,
      due_date: values.due_date ? values.due_date.format('YYYY-MM-DD') : null,
      customer_id: selectedCustomer.id,
      bakery_id: selectedBakery.id,
    };

    await updateNote(editingNote.id, formattedValues, token);
    setEditingNote(null);
    refreshNotes();
  };

  // Function to handle note deletion
  const handleDeleteNote = async (noteId) => {
    await deleteNote(noteId, token);
    refreshNotes();
  };

  
  const columns = [
    {
      title: translations[language].firstName,
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: translations[language].lastName,
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: translations[language].phoneNumber,
      dataIndex: "phone_number",
      key: "phone_number",
    },
  ];

  return (
    <div style={{ display: "flex", height: "100vh", overflow: "hidden" }}>
      <div style={{ flex: 1, overflow: "hidden" }}>
        <Row gutter={[16, 16]}>
          <Col span={16}>
            <Title level={2}>{translations[language].customers}</Title>
            <Button type="primary" onClick={() => setIsModalVisible(true)}>
              {translations[language].addCustomer}
            </Button>
            <Table
              dataSource={customers}
              columns={columns}
              rowKey="id"
              onRow={(record) => {
                return {
                  onClick: () => handleRowClick(record),
                };
              }}
            />
            <Modal
              title={translations[language].addCustomer}
              visible={isModalVisible}
              onOk={handleAddCustomer}
              onCancel={() => setIsModalVisible(false)}
            >
              <Form layout="vertical">
                <Form.Item label={translations[language].firstName}>
                  <Input
                    onChange={(e) =>
                      setFormData({ ...formData, firstName: e.target.value })
                    }
                  />
                </Form.Item>
                <Form.Item label={translations[language].lastName}>
                  <Input
                    onChange={(e) =>
                      setFormData({ ...formData, lastName: e.target.value })
                    }
                  />
                </Form.Item>
                <Form.Item label={translations[language].phoneNumber}>
                  <Input
                    onChange={(e) =>
                      setFormData({ ...formData, phoneNumber: e.target.value })
                    }
                  />
                </Form.Item>
              </Form>
            </Modal>
          </Col>
          <Col span={8}>
            {selectedCustomer && (
              <div style={{ maxHeight: "100vh", overflowY: "auto" }}>
                <Card
                  title={translations[language].customerDetails}
                  extra={
                    <>
                      <Button
                        type="link"
                        onClick={() => {
                          setFormData({
                            firstName: selectedCustomer.first_name,
                            lastName: selectedCustomer.last_name,
                            phoneNumber: selectedCustomer.phone_number,
                          });
                          setIsEditModalVisible(true);
                        }}
                      >
                        {translations[language].update}
                      </Button>
                      <Button type="link" onClick={closePanel}>
                        {translations[language].close}
                      </Button>
                    </>
                  }
                >
                  <Text strong>{translations[language].firstName}: </Text>
                  <Text>{selectedCustomer.first_name}</Text>
                  <br />
                  <Text strong>{translations[language].lastName}: </Text>
                  <Text>{selectedCustomer.last_name}</Text>
                  <br />
                  <Text strong>{translations[language].phoneNumber}: </Text>
                  <Text>{selectedCustomer.phone_number}</Text>
                  <br />
                  <Tabs defaultActiveKey="1">
                    <TabPane tab={translations[language].appointments} key="1">
                      {/* List of Appointments */}
                      <List
                        itemLayout="horizontal"
                        dataSource={selectedCustomer.appointments}
                        renderItem={(item) => {
                          const appointmentDate = new Date(
                            item.appointment_date_time
                          );
                          const formattedDate =
                            appointmentDate.toLocaleDateString("en-US");
                          const formattedTime =
                            appointmentDate.toLocaleTimeString("en-US", {
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            });
                          const hebrewDayLetter = getHebrewDayLetter(
                            appointmentDate.getDay()
                          );
                          const hebrewWeekName = getHebrewWeekName(
                            item.appointment_date_time
                          );
                          return (
                            <List.Item>
                              <List.Item.Meta
                                title={
                                  <a
                                    onClick={() =>
                                      navigate(`/appointments/${item.id}`)
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    {`Appointment on ${formattedDate}, ${formattedTime} - ${hebrewDayLetter} ${hebrewWeekName}`}
                                  </a>
                                }
                                description={
                                  <>
                                    <Text>
                                      Comments: {item.comments || "N/A"}
                                    </Text>
                                    <br />
                                    <Text>
                                      {translations[language].pound}:{" "}
                                      {item.pounds || "N/A"}
                                    </Text>
                                    <br />
                                    <Text>
                                      {translations[language].bakingMethods}:{" "}
                                      {item.baking_methods_details
                                        .map((method) => method.name)
                                        .join(", ") || "N/A"}
                                    </Text>
                                    <br />
                                    <Text>
                                      {translations[language].flourTypes}:{" "}
                                      {item.flour_types_details
                                        .map((flour) => flour.name)
                                        .join(", ") || "N/A"}
                                    </Text>
                                  </>
                                }
                              />
                            </List.Item>
                          );
                        }}
                      />
                    </TabPane>
                    <TabPane tab="Notes" key="2">
                      {!editingNote ? (
                        <>
                          <Button
                            type="primary"
                            onClick={() =>
                              setEditingNote({ content: "", due_date: "" })
                            }
                          >
                            Add Note
                          </Button>

                          <List
                            itemLayout="horizontal"
                            dataSource={customerNotes}
                            renderItem={(note) => (
                              <List.Item
                                actions={[
                                  <Button
                                    type="dashed"
                                    shape="circle"
                                    icon={<EditOutlined />}
                                    onClick={() => setEditingNote(note)}
                                  ></Button>,
                                  <Button
                                    type="dashed"
                                    shape="circle"
                                    icon={<DeleteOutlined />}
                                    danger
                                    onClick={() => handleDeleteNote(note.id)}
                                  ></Button>,
                                ]}
                              >
                                <List.Item.Meta
                                  title={note.content}
                                  description={`Due: ${
                                    note.due_date || "N/A"
                                  } - Status: ${note.status}`}
                                />
                              </List.Item>
                            )}
                          />
                        </>
                      ) : (
                        <Form
                          onFinish={
                            editingNote.id ? handleUpdateNote : handleCreateNote
                          }
                          initialValues={{
                            ...editingNote,
                            due_date: editingNote.due_date
                              ? moment(editingNote.due_date)
                              : null,
                          }}
                          layout="vertical"
                        >
                          <Form.Item
                            name="content"
                            label="Content"
                            initialValue={editingNote.content}
                            rules={[{ required: true, message: "Please input content!" }]}
                          >
                            <TextArea rows={7} />
                          </Form.Item>
                          <Row gutter={8}>
                            <Col span={12}>
                              <Form.Item name="due_date" label="Due Date">
                                <DatePicker style={{ width: "100%" }} />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                name="status"
                                label="Status"
                                initialValue="open"
                              >
                                <Select>
                                  <Option value="open">Open</Option>
                                  <Option value="completed">Completed</Option>
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                          <Button type="primary" htmlType="submit">
                            {editingNote.id ? "Update Note" : "Create Note"}
                          </Button>
                          <Button onClick={() => setEditingNote(null)}>
                            Cancel
                          </Button>
                        </Form>
                      )}
                    </TabPane>
                  </Tabs>
                </Card>
              </div>
            )}
          </Col>
          <Modal
            title={translations[language].editCustomer}
            visible={isEditModalVisible}
            onOk={handleEditCustomer}
            onCancel={() => setIsEditModalVisible(false)}
          >
            <Form layout="vertical">
              <Form.Item label={translations[language].firstName}>
                <Input
                  value={formData.firstName}
                  onChange={(e) =>
                    setFormData({ ...formData, firstName: e.target.value })
                  }
                />
              </Form.Item>
              <Form.Item label={translations[language].lastName}>
                <Input
                  value={formData.lastName}
                  onChange={(e) =>
                    setFormData({ ...formData, lastName: e.target.value })
                  }
                />
              </Form.Item>
              <Form.Item label={translations[language].phoneNumber}>
                <Input
                  value={formData.phoneNumber}
                  onChange={(e) =>
                    setFormData({ ...formData, phoneNumber: e.target.value })
                  }
                />
              </Form.Item>
            </Form>
          </Modal>
        </Row>
      </div>
    </div>
  );
}

export default CustomersPage;
