import React, { useContext, useState, useEffect } from 'react';
import { List, Card, Button, Modal, Form, Input, InputNumber } from 'antd';
import { GlobalContext } from '../GlobalContext';
import { fetchWorkersForBakery, createWorker } from '../services/apiService';
import translations from '../translations';

function WorkersPage() {
  const { selectedBakery, token, language } = useContext(GlobalContext);
  const [workers, setWorkers] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (selectedBakery && token) {
      loadWorkers();
    }
  }, [selectedBakery, token]);

  const loadWorkers = async () => {
    try {
      const workersData = await fetchWorkersForBakery(selectedBakery.id, token);
      setWorkers(workersData);
    } catch (error) {
      console.error("Error fetching workers:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      formData.bakery_id = selectedBakery.id;
      await createWorker(formData, token);
      setIsModalVisible(false);
      loadWorkers();
    } catch (error) {
      console.error("Error creating worker:", error);
    }
  };

  return (
    <div>
      <Button type="primary" onClick={() => setIsModalVisible(true)}>
        {translations[language].addWorker}
      </Button>
      <Modal title="Add New Worker" visible={isModalVisible} onOk={handleSubmit} onCancel={() => setIsModalVisible(false)}>
        <Form>
          <Form.Item label="First Name">
            <Input name="first_name" onChange={handleInputChange} />
          </Form.Item>
          <Form.Item label="Last Name">
            <Input name="last_name" onChange={handleInputChange} />
          </Form.Item>
          <Form.Item label="Phone Number">
            <Input name="phone_number" onChange={handleInputChange} />
          </Form.Item>
          <Form.Item label="Job Title">
            <Input name="job" onChange={handleInputChange} />
          </Form.Item>
          <Form.Item label="Rate">
            <InputNumber name="rate" onChange={value => setFormData(prev => ({ ...prev, rate: value }))} />
          </Form.Item>
          <Form.Item label="Comment">
            <Input name="comment" onChange={handleInputChange} />
          </Form.Item>
        </Form>
      </Modal>
      <List
        grid={{ gutter: 16, column: 4 }}
        dataSource={workers}
        renderItem={worker => (
          <List.Item>
            <Card title={`${worker.first_name} ${worker.last_name}`}>
              <p>Phone: {worker.phone_number}</p>
              <p>Job: {worker.job}</p>
              <p>Rate: ${worker.rate}</p>
              <p>Comment: {worker.comment}</p>
            </Card>
          </List.Item>
        )}
      />
    </div>
  );
}

export default WorkersPage;
